import React, { useCallback, useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Input } from 'antd';
import { ChatPrivilege } from '@zoom/videosdk';
import ZoomContext from '../../context/zoom-context';
import ChatMessageItem from './component/chat-message-item';
import './chat.scss';
import { IconFont } from '../../component/icon-font';
import useAxiosPrivate from '../../hooks/useAxiosBase';
import { ENDPOINTS } from '../../services/urls';

const { TextArea } = Input;
interface ChatContainerProps {
  setIsChatWindowOpen: (state: boolean) => void;
  isChatWindowOpen: boolean;
  records: any;
  setRecords: (state: any) => void;
  unreadCount: any;
  setUnreadCount: (state: any) => void;
}
const ChatContainer = ({
  setIsChatWindowOpen,
  isChatWindowOpen,
  records,
  setRecords,
  unreadCount,
  setUnreadCount
}: ChatContainerProps) => {
  const axios = useAxiosPrivate();
  const zmClient = useContext(ZoomContext);
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);

  const onChatInput = (event: any) => {
    setChatDraft(event.target.value);
  };
  const sendMessage = useCallback((text: string) => {
    const body = {
      message: text,
      attachment: ''
    };
    axios.post(ENDPOINTS.SEND_MESSAGE, body).then((res) => {
      if (res.data.isSuccess) {
        setRecords((prev: any) => [res.data.chatDetails, ...prev]);
      }
    });
  }, []);
  const onChatPressEnter = useCallback(
    (event: any) => {
      event.preventDefault();
      if (chatDraft) {
        sendMessage(chatDraft);
        setChatDraft('');
      }
    },
    [sendMessage, chatDraft]
  );
  useLayoutEffect(() => {
    if (chatWrapRef.current) {
      chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
    }
  }, [unreadCount.messageCount]);
  return (
    <div className="chat-container  z-[9999]">
      <div className="chat-wrap">
        <div
          className="flex w-full justify-end items-center"
          onClick={() => {
            setIsChatWindowOpen(!isChatWindowOpen);
          }}
        >
          <button>
            <img src="./icons/close.svg" alt="" />
          </button>
        </div>
        <div className="chat-message-wrap scrollbar-thin mt-[1rem] " ref={chatWrapRef}>
          {records.length > 0 ? (
            <>
              {records.map((record: any) => (
                <ChatMessageItem
                  record={record}
                  currentUserId={zmClient.getSessionInfo().userId}
                  key={record.timestamp}
                  // resendFile={resendFile}
                  // downloadFile={downloadFile}
                />
              ))}
            </>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <div className="text-[#FFFF] text-[1.5rem]">
                <span>No new messages</span>
              </div>{' '}
            </div>
          )}
        </div>
        <>
          <div className="w-full h-[1px] bg-[#eee] mt-1 "></div>
          <div className="chat-message-box ">
            <input
              className="outline-none"
              onChange={(e) => {
                onChatInput(e);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onChatPressEnter(e);
                }
              }}
              value={chatDraft}
              placeholder="Type message here ..."
            />
            <button
              className=" flex justify-center  items-center h-[3rem] w-[3rem] bg-primary rounded-full "
              onClick={onChatPressEnter}
            >
              <IconFont type="icon-send"></IconFont>
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default ChatContainer;
