/* eslint-disable no-restricted-globals */
import React from 'react';

const AuthError = () => {
  return (
    <div className="h-[100vh] w-[100vw] flex justify-center items-center relative flex-col bg-[#000000]">
      <div className="absolute top-0 right-0">
        <img src="./icons/stars.svg" alt="" />
      </div>
      <div className="absolute bottom-0 left-0">
        <img src="./icons/stars.svg" alt="" />
      </div>
      <div>
        <img src="./icons/authError.svg" alt="" />
      </div>
      <div className="mt-[2.3rem]">
        <span className="text-[#FFFF] text-[2rem]">Something went wrong</span>
      </div>
    </div>
  );
};
export default AuthError;
