const BASE_URLS: any = {
  DEV: 'https://dev.api.qyxyp.interviewbetter.io',
  QA: 'https://qa.api.qyxyp.interviewbetter.io',
  UAT: 'https://demo.api.qyxyp.interviewbetter.io',
  STAGING: '',
  PRODUCTION: '',
  LOCAL: 'https://dev.api.qyxyp.interviewbetter.io'
};
const PARENT_URLS: any = {
  DEV: 'https://dev.qyxyp.interviewbetter.io',
  QA: 'https://qa.qyxyp.interviewbetter.io',
  UAT: 'https://demo.qyxyp.interviewbetter.io',
  STAGING: '',
  PRODUCTION: '',
  LOCAL: 'https://dev.qyxyp.interviewbetter.io'
};

// Define service paths
const SERVICES = {
  INTERVIEW: 'interview'
};

// Function to generate service URLs
const generateServiceUrls = (environment: any) => {
  const baseUrl = BASE_URLS[environment];
  return Object.fromEntries(Object.entries(SERVICES).map(([key, path]) => [key, `${baseUrl}/${path}`]));
};

// Set the current environment
const CURRENT_ENVIRONMENT = process.env.REACT_APP_ENVIORNMENT || 'DEV';
export const PARENT_URL = PARENT_URLS[CURRENT_ENVIRONMENT];
// Generate service URLs for the current environment
const SERVICES_URLS = generateServiceUrls(CURRENT_ENVIRONMENT);
export const ENDPOINTS = {
  //Zoom
  GENERATE_JWT: SERVICES_URLS.INTERVIEW + '/api/Zoom/GenerateJWT',
  GET_EXITREASONS: SERVICES_URLS.INTERVIEW + '/api/Interview/GetEarlyExitReason',
  ADD_REASON: SERVICES_URLS.INTERVIEW + '/api/Interview/AddReasonForEarlyExit',
  WHITEBOARD: SERVICES_URLS.INTERVIEW + '/api/Whiteboard',
  WHITEBOARDHUB: SERVICES_URLS.INTERVIEW + '/whiteboardHub',
  SEND_MESSAGE: SERVICES_URLS.INTERVIEW + '/api/Chat/SendMessage',
  GET_ALL_MESSAGES: SERVICES_URLS.INTERVIEW + '/api/Chat/GetChat',
  HAS_NEW_MESSAGE: SERVICES_URLS.INTERVIEW + '/api/Chat/HasNewMessage',
  GET_POLL_DURATION: SERVICES_URLS.INTERVIEW + '/api/Chat/GetShorPollDuration',
  CONFIRM_INTERVIEWER_PARTICIPATION: SERVICES_URLS.INTERVIEW + '/api/Interview/ConfirmInterviewerInterviewParicipation'
};
