import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { VideoQuality } from '@zoom/videosdk';
import classnames from 'classnames';
import _ from 'lodash';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';
import ReportBtn from './components/report-btn';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { Participant } from '../../index-types';
import { SELF_VIDEO_ID } from './video-constants';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import { useAvatarAction } from './hooks/useAvatarAction';
import { usePrevious } from '../../hooks';
import './video.scss';
import { isShallowEqual } from '../../utils/util';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosBase';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Whiteboard from './whiteboard';
import IntervieweeWhiteBoard from './intervieweeWhiteBoard';
import { AnimatePresence, motion } from 'framer-motion';
import { ENDPOINTS } from '../../services/urls';
import { Modal } from 'antd';
const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);

  const {
    auth,
    showWhiteBoardSection,
    currentWhiteBoardId,
    setCurrentWhiteBoardId,
    whiteBoardList,
    setShowWhiteBoardSection,
    setWhiteBoardList
  }: any = useAuth();
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const selfVideoCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [activeVideo, setActiveVideo] = useState<number>(mediaStream?.getActiveVideoId() ?? 0);
  const previousActiveUser = useRef<Participant>();
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const selfCanvasDimension = useCanvasDimension(mediaStream, selfVideoCanvasRef);
  const networkQuality = useNetworkQuality(zmClient);
  const previousCanvasDimension = usePrevious(canvasDimension);
  const axios = useAxiosPrivate();
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [prevParticipantsLength, setPrevParticipantsLength] = useState(0);
  const [maxParticipants, setMaxparticipants] = useState(0);

  useEffect(() => {
    zmClient.on('user-removed', (payload) => {
      console.log(payload, 'payloadd');
      if (payload[0].displayName == 'Candidate') {
        Modal.info({
          title: 'Candidate has left the meeting',
          style: {
            backgroundColor: '#f0f0f0'
          },

          okType: 'text',
          okText: ' ',
          onOk: () => {
            Modal.destroyAll();
          }
        });
        setTimeout(() => {
          Modal.destroyAll();
        }, 3000);
      }
    });
  }, [zmClient]);
  useParticipantsChange(zmClient, (payload) => {
    console.log(payload, 'payloadd participants');
    setParticipants(payload);
    if (payload.length !== prevParticipantsLength) {
      // Update previous length for next comparison
      setPrevParticipantsLength(payload.length);
      if (payload.length > maxParticipants) {
        setMaxparticipants(payload.length);
      }
      // Check if interviewee has left
    }
  });
  const onActiveVideoChange = useCallback((payload: any) => {
    const { userId } = payload;
    setActiveVideo(userId);
  }, []);
  useEffect(() => {
    zmClient.on('video-active-change', onActiveVideoChange);
    return () => {
      zmClient.off('video-active-change', onActiveVideoChange);
    };
  }, [zmClient, onActiveVideoChange]);

  // active user = regard as `video-active-change` payload, excluding the case where it is self and the video is turned on.
  // In this case, the self video is rendered seperately.
  const activeUser = useMemo(
    () =>
      participants.find(
        (user) => user.userId === activeVideo && !(user.userId === zmClient.getSessionInfo().userId && user.bVideoOn)
      ),
    [participants, activeVideo, zmClient]
  );
  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
        //
        if (
          activeUser?.bVideoOn &&
          !(activeUser.userId === zmClient.getSessionInfo().userId && isCurrentUserStartedVideo)
        ) {
          mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P as any
          );
        } else {
          if (previousActiveUser.current?.bVideoOn) {
            mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
          }
        }
      }
      if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn) {
        if (activeUser.userId !== previousActiveUser.current.userId) {
          mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
          mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P as any
          );
        } else {
          if (!isShallowEqual(canvasDimension, previousCanvasDimension)) {
            mediaStream.adjustRenderedVideoPosition(
              videoRef.current,
              activeUser.userId,
              canvasDimension.width,
              canvasDimension.height,
              0,
              0
            );
          }
        }
      }
      previousActiveUser.current = activeUser;
    }
  }, [
    mediaStream,
    activeUser,
    isVideoDecodeReady,
    canvasDimension,
    previousCanvasDimension,
    zmClient,
    isCurrentUserStartedVideo
  ]);
  useEffect(() => {
    if (
      selfVideoCanvasRef.current &&
      // selfCanvasDimension.width > 0 &&
      // selfCanvasDimension.height > 0 &&
      isCurrentUserStartedVideo
    ) {
      mediaStream?.adjustRenderedVideoPosition(
        selfVideoCanvasRef.current,
        zmClient.getSessionInfo().userId,
        300,
        200,
        0,
        0
      );
    }
  }, [selfCanvasDimension, mediaStream, zmClient, isCurrentUserStartedVideo]);
  const avatarActionState = useAvatarAction(zmClient, activeUser ? [activeUser] : []);

  useEffect(() => {
    const whiteboard =
      whiteBoardList.find((item: Whiteboard) => item.name && item.name.trim() !== '') ||
      whiteBoardList.find((item: Whiteboard) => item.name === null || item.name.trim() === '');
    let id = whiteboard ? whiteboard.id : null;
    setCurrentWhiteBoardId(id);
    const startConnection = async () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(ENDPOINTS.WHITEBOARDHUB)
        .configureLogging(LogLevel.Information)
        .build();

      newConnection.on('ReceiveDrawing', (userId: string, data: string) => {
        console.log('Text updated by user:', userId);
      });

      newConnection.on('AccessDenied', (message: string) => {
        alert(message);
      });

      newConnection.on('DrawingLocked', (message: string) => {
        alert(message);
      });

      try {
        await newConnection.start();
        console.log('Connected to SignalR Hub');
        await newConnection.invoke('JoinWhiteboard', id);
        console.log('Joined whiteboard:', id);
      } catch (error) {
        console.error('Connection error:', error);
      }

      setConnection(newConnection);
    };

    startConnection();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (auth.token && auth.role === '2') {
      getWhiteBoardIds();
      const interval = setInterval(() => {
        getWhiteBoardIds();
      }, 6000);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [auth]);

  const getWhiteBoardIds = async () => {
    if (auth.token) {
      const url = ENDPOINTS.WHITEBOARD + '/GetWhiteBoardIds?sessionId=' + auth.sessionId;
      try {
        const response = await axios.get(url);
        const output = response.data;

        if (output && output.whiteboards) {
          setWhiteBoardList(output.whiteboards);

          // Check if any whiteboard has hasAccessGiven set to true
          const accessGrantedBoard = output.whiteboards.find((board: Whiteboard) => board.hasAccessGiven === true);

          if (accessGrantedBoard) {
            setShowWhiteBoardSection(true);
            setCurrentWhiteBoardId(accessGrantedBoard.id);
          } else {
            setShowWhiteBoardSection(false); // Optionally set this if no access is given
          }
        } else {
          console.log('No whiteboards found.');
        }
      } catch (error) {
        console.error('Error fetching whiteboard IDs:', error);
      }
    }
  };
  return (
    <div className="viewport">
      {/* {showWhiteBoardSection ? (
        auth.role === '1' ? (
          <AnimatePresence>
            <motion.div
              key="whiteboardsection"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, type: 'spring' }}
            >
              <Whiteboard whiteBoardId={currentWhiteBoardId} onHide={() => setShowWhiteBoardSection(false)} />
            </motion.div>
          </AnimatePresence>
        ) : (
          <AnimatePresence>
            <motion.div
              key="anothersection"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, type: 'spring' }}
            >
              <IntervieweeWhiteBoard whiteBoardId={currentWhiteBoardId} />
            </motion.div>
          </AnimatePresence>
        )
      ) : (
        <>
          <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
          <div
            className={classnames('video-container', 'single-video-container', {
              'video-container-in-sharing': isRecieveSharing
            })}
          >
            {mediaStream?.isRenderSelfViewWithVideoElement() ? (
              <video
                id={SELF_VIDEO_ID}
                autoPlay
                muted
                playsInline
                className={classnames('self-video', {
                  'single-self-video': participants.length === 1,
                  'self-video-show': isCurrentUserStartedVideo
                })}
              />
            ) : (
              <canvas
                id={SELF_VIDEO_ID}
                width="254"
                height="143"
                className={classnames('self-video', {
                  'single-self-video': participants.length === 1,
                  'self-video-show': isCurrentUserStartedVideo
                })}
                ref={selfVideoCanvasRef}
              />
            )}
            <div className="single-video-wrap">
              <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />

              <AvatarActionContext.Provider value={avatarActionState}>
                {activeUser && (
                  <Avatar
                    participant={activeUser}
                    isActive={false}
                    className="single-view-avatar"
                    networkQuality={networkQuality[`${activeUser.userId}`]}
                  />
                )}
              </AvatarActionContext.Provider>
            </div>
          </div>
        </>
      )} */}
      <div
        className={` z-[9999]  ${
          showWhiteBoardSection && auth.role === '1' ? 'opacity-100' : 'opacity-0'
        } absolute top-0 left-0 w-full z-20 transition-opacity duration-500 ${
          showWhiteBoardSection && auth.role === '1' ? 'block' : 'hidden'
        }`}
      >
        <Whiteboard whiteBoardId={currentWhiteBoardId} onHide={() => setShowWhiteBoardSection(false)} />
      </div>

      <div
        className={` z-[9999]  ${
          showWhiteBoardSection && auth.role !== '1' ? 'opacity-100' : 'opacity-0'
        } absolute top-0 left-0 w-full z-20 transition-opacity duration-500 ${
          showWhiteBoardSection && auth.role !== '1' ? 'block' : 'hidden'
        }`}
      >
        <IntervieweeWhiteBoard whiteBoardId={currentWhiteBoardId} />
      </div>

      <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
      <div
        className={classnames('video-container', 'single-video-container', {
          'video-container-in-sharing': isRecieveSharing,
          'video-container-whiteboard': showWhiteBoardSection
        })}
      >
        {mediaStream?.isRenderSelfViewWithVideoElement() ? (
          <video
            id={SELF_VIDEO_ID}
            autoPlay
            disablePictureInPicture
            muted
            playsInline
            className={classnames('self-video', {
              'single-self-video': participants.length === 1,
              'self-video-show': isCurrentUserStartedVideo,
              'single-video-whiteboard': showWhiteBoardSection
            })}
          />
        ) : (
          <canvas
            id={SELF_VIDEO_ID}
            className={classnames('self-video ', {
              'single-self-video': participants.length === 1,
              'self-video-show': isCurrentUserStartedVideo,
              'single-video-whiteboard': showWhiteBoardSection
            })}
            ref={selfVideoCanvasRef}
          />
        )}
        {!isCurrentUserStartedVideo && (
          <div
            className={classnames('self-video ', {
              'self-video-show': participants.length > 1,
              'single-video-whiteboard': showWhiteBoardSection
            })}
          >
            <Avatar
              participant={zmClient.getCurrentUserInfo()}
              key={zmClient.getCurrentUserInfo().userId}
              isActive={false}
              networkQuality={networkQuality[`${zmClient.getCurrentUserInfo().userId}`]}
            />
          </div>
        )}
        <div className="single-video-wrap">
          <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />

          <AvatarActionContext.Provider value={avatarActionState}>
            {activeUser && (
              <Avatar
                participant={activeUser}
                isActive={false}
                className="single-view-avatar"
                networkQuality={networkQuality[`${activeUser.userId}`]}
              />
            )}
            <RemoteCameraControlPanel />
          </AvatarActionContext.Provider>
        </div>
      </div>
      <VideoFooter className="video-operations" sharing selfShareCanvas={shareViewRef.current?.selfShareRef} />
    </div>
  );
};

export default VideoContainer;
