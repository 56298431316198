import React, { useCallback } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import classNames from 'classnames';
import { ChatRecord } from '../chat-types';
import './chat-message-item.scss';
interface ChatMessageItemProps {
  record: any;
  currentUserId: number;
}
const ChatMessageItem = (props: ChatMessageItemProps) => {
  const { record, currentUserId } = props;
  const { message, isSender, sendDateTime } = record;

  const chatMessage = Array.isArray(message) ? message : [message];
  return (
    <div className={classNames('chat-message-item', { myself: isSender })}>
      {!isSender && (
        <Button className="chat-message-avatar" ghost shape="circle" size="large">
          {<UserOutlined />}
        </Button>
      )}

      <div className="chat-message-content scrollbar-thin">
        <ul className={classNames('chat-message-text-list', { myself: isSender })}>
          {chatMessage.map((text, index) => (
            <li className={classNames('chat-message-text')} key={index}>
              {text}
            </li>
          ))}
        </ul>

        <div className={classNames('chat-message-info', { myself: isSender })}>
          <p className="chat-message-time">
            {new Date(sendDateTime).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true // for 12-hour format (AM/PM)
            })}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ChatMessageItem;
