import React, { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import useAuth from '../../hooks/useAuth';
import { ENDPOINTS } from '../../services/urls';
import useAxiosPrivate from '../../hooks/useAxiosBase';
import CustomModal from './components/modal';
import { motion, AnimatePresence } from 'framer-motion';
interface WhiteboardProps {
  whiteBoardId: string;
}
interface Whiteboard {
  id: string;
  name: string | null;
  hasAccessGiven: boolean;
}

const IntervieweeWhiteBoard: React.FC<WhiteboardProps> = ({ whiteBoardId }) => {
  const { auth, whiteBoardList, setWhiteBoardList, setCurrentWhiteBoardId, currentWhiteBoardId }: any = useAuth();

  const axios = useAxiosPrivate();
  const [collabText, setCollabText] = useState<string>('');
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const startConnection = async () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(ENDPOINTS.WHITEBOARDHUB)
        .configureLogging(LogLevel.Information)
        .build();

      newConnection.on('ReceiveDrawing', (userId: string, data: string) => {
        setCollabText(data);
        console.log('Text updated by user:', userId);
      });

      newConnection.on('AccessDenied', (message: string) => {
        alert(message);
      });

      newConnection.on('DrawingLocked', (message: string) => {
        alert(message);
      });

      try {
        await newConnection.start();
        console.log('Connected to SignalR Hub');
        setConnection(newConnection);
      } catch (error) {
        console.error('Connection error:', error);
      }
    };

    startConnection();

    return () => {
      connection?.stop();
    };
  }, []);

  useEffect(() => {
    const joinWhiteboard = async () => {
      setIsLoading(true);

      if (connection) {
        try {
          await connection.invoke('JoinWhiteboard', whiteBoardId);
          getWhiteBoardIdData(whiteBoardId);
        } catch (error) {
          console.error('Error joining whiteboard or fetching content:', error);
        }
      }
    };

    joinWhiteboard();
  }, [whiteBoardId, connection]);
  const getWhiteBoardIdData = (whiteBoardId: string) => {
    axios.get(ENDPOINTS.WHITEBOARD + '/GetAllWhiteboardData?whiteboardId=' + whiteBoardId).then((res: any) => {
      if (!res.data) {
      } else {
        setCollabText(res.data.data);
        setIsLoading(false);
      }
    });
  };

  const broadcastTextUpdate = async (text: any) => {
    if (connection) {
      try {
        await connection.invoke('BroadcastDrawing', whiteBoardId, text, auth?.token?.replaceAll(' ', '+'));
        console.log('Text update sent successfully.');
      } catch (error) {
        console.error('Error sending text update:', error);
      }
    }
  };

  return (
    <div className=" justify-center items-start h-[calc(79vh+5vh)] w-screen grid grid-cols-12 bg-[#f5f5f5] pt-5 pb-5">
      <div className="flex flex-col items-center col-span-9">
        <div className="!bg-[#ffff] rounded-[12px] p-5 shadow-none flex flex-col w-[90%] min-h-[490px] mb-4">
          <h2 className="text-lg font-bold mb-2 text-left">
            {whiteBoardList.find((board: Whiteboard) => board.id === whiteBoardId)?.name || 'Untitled Board'}
          </h2>
          <div className="flex flex-col flex-1">
            {isLoading ? (
              <div className="flex justify-center items-center flex-1">
                <motion.div
                  className="w-10 h-10 border-4 border-t-primary border-[#f3f3f3] rounded-full"
                  animate={{ rotate: 360 }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                    ease: 'linear'
                  }}
                />
              </div>
            ) : (
              <textarea
                id="collabTextBox"
                className="flex-1 rounded-[8px] p-2 text-base resize-none border border-[#f3f3f3] bg-[#f3f3f3] mt-2"
                value={collabText}
                onChange={(e) => {
                  setCollabText(e.target.value);
                  broadcastTextUpdate(e.target.value);
                }}
                style={{ height: '100%', minHeight: '0' }} // Ensures full height
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntervieweeWhiteBoard;
